@import "variables";

.bg-white {
    background-color: $body-bg;
}

.login-logo,
.register-logo {
    font-size: 2.1rem;
    font-weight: 300;
    margin-bottom: 0.9rem;
    text-align: center;
}

.login-logo a,
.register-logo a {
    color: #495057;
}

.login-page,
.register-page {
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #e9ecef;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100vh;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.login-box,
.register-box {
    width: 400px;
}

@media (max-width: 576px) {
    .login-box,
    .register-box {
        margin-top: 0.5rem;
        width: 90%;
    }
}

.login-box .card,
.register-box .card {
    margin-bottom: 0;
}

.login-card-body,
.register-card-body {
    background-color: #fff;
    border-top: 0;
    color: #666;
    padding: 20px;
}

.login-card-body .input-group .form-control,
.register-card-body .input-group .form-control {
    border-right: 0;
}

.login-card-body .input-group .form-control:focus,
.register-card-body .input-group .form-control:focus {
    box-shadow: none;
}

.login-card-body
    .input-group
    .form-control:focus
    ~ .input-group-prepend
    .input-group-text,
.login-card-body
    .input-group
    .form-control:focus
    ~ .input-group-append
    .input-group-text,
.register-card-body
    .input-group
    .form-control:focus
    ~ .input-group-prepend
    .input-group-text,
.register-card-body
    .input-group
    .form-control:focus
    ~ .input-group-append
    .input-group-text {
    border-color: #80bdff;
}

.login-card-body .input-group .form-control.is-valid:focus,
.register-card-body .input-group .form-control.is-valid:focus {
    box-shadow: none;
}

.login-card-body
    .input-group
    .form-control.is-valid
    ~ .input-group-prepend
    .input-group-text,
.login-card-body
    .input-group
    .form-control.is-valid
    ~ .input-group-append
    .input-group-text,
.register-card-body
    .input-group
    .form-control.is-valid
    ~ .input-group-prepend
    .input-group-text,
.register-card-body
    .input-group
    .form-control.is-valid
    ~ .input-group-append
    .input-group-text {
    border-color: #28a745;
}

.login-card-body .input-group .form-control.is-invalid:focus,
.register-card-body .input-group .form-control.is-invalid:focus {
    box-shadow: none;
}

.login-card-body
    .input-group
    .form-control.is-invalid
    ~ .input-group-append
    .input-group-text,
.register-card-body
    .input-group
    .form-control.is-invalid
    ~ .input-group-append
    .input-group-text {
    border-color: #dc3545;
}

.login-card-body .input-group .input-group-text,
.register-card-body .input-group .input-group-text {
    background-color: transparent;
    border-bottom-right-radius: 0.25rem;
    border-left: 0;
    border-top-right-radius: 0.25rem;
    color: #777;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.login-box-msg,
.register-box-msg {
    margin: 0;
    padding: 0 20px 20px;
    text-align: center;
}
