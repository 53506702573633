// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");
// Variables
@import "common";
// AdminLTE
@import "~admin-lte/dist/css/adminlte.css";
@import "~@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "~@fortawesome/fontawesome-free/scss/regular";
@import "~@fortawesome/fontawesome-free/scss/brands";
@import "~@fortawesome/fontawesome-free/scss/solid";

@import "login.scss";

@import "custom.scss";

.blue {
    color: $blue;
}
.indigo {
    color: $indigo;
}
.purple {
    color: $purple;
}
.pink {
    color: $pink;
}
.red {
    color: $red;
}
.orange {
    color: $orange;
}
.yellow {
    color: $yellow;
}
.green {
    color: $green;
}
.teal {
    color: $teal;
}
.cyan {
    color: $cyan;
}

// .content-header{
//   padding: 10px 0.5rem !important;
// }
