
@import "variables";

.ui-datepicker{
  z-index: 1039 !important;
}
.main-sidebar{
  overflow-y: unset;
  .sidebar{
    overflow-y: unset !important;
  }
}

//fixed height of select 2
.select2-selection__arrow, .select2-selection--single{
  height: calc(2.25rem + 2px) !important;
}
/*
fixed th,td padding
*/
.table th,
.table td {
    padding-bottom: 0.25rem !important;
}

.cst-progress {
  z-index: 9998;
  cursor: wait;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;

  button {
    position: absolute;
    top: 3px;
    right: 45%;
    z-index: 9999;
  }
}

/*
fixed body croll*/
.wrapper .content-wrapper {
    min-height: calc(100vh - calc(3.5rem + 1px) - calc(3.5rem + 1px)) !important;
}
//fixed table scroll
.panel-table-scroll{
    overflow-y: scroll;
    height: calc(100vh - 300px);
}
.paginator{
    padding-top: 2rem;
}
