.main-sidebar > .brand-link > .brand-image {
    width: 50px;
    float: left;
    line-height: 0.8;
    margin-right: 0.5rem;
    max-height: 33px;
    margin-left: unset;
    margin-top: unset;
}

.no-hover:hover {
    // color: red !important;
    background-color: unset !important;
}

#products-sub-table > tbody > tr {
    font-weight: 400;
}

#table-products {
    .plus:before {
        font-family: "Font Awesome 5 Free";
        content: "\f067";
        margin-top: -3px;
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        font-weight: 900;
    }

    .minus::before {
        font-family: "Font Awesome 5 Free";
        content: "\f068";
        margin-top: -3px;
        display: inline-block;
        padding-right: 3px;
        vertical-align: middle;
        font-weight: 900;
    }
}

.custom-detail-div {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 700;
}

.custom-detail-div2 {
    display: flex;
    align-items: center;
    font-size: 1rem;
    // font-weight: 700;
}

#form-item-detail #modal-num-item,
#form-item-detail #modal-num-case {
    text-align: center;
}

@media screen and (min-width: 769px){
    #form-item-detail #modal-ship-grp-key-label, 
    #form-item-detail #disabled-flag-label {
        text-align: right;
    }
}

// #modal-product-header .materials{
//     display: flex;
//     justify-content: space-around;
//     margin-left: 7.5px;
//     margin-right: 7.5px;
// }

#modal-product-header .materials .materials-title{
    display: flex;
    justify-content: space-between;
    color: #ffffff;
}

#modal-product-header .materials .materials-title button{
    margin: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

#modal-product-header .materials .materials-content select{
    width: 100%;
    height: 100px;
}

#modal-product-header .materials .materials-content .button-add-lotno{
    width: 100%;
}

#form-product-create .table-label th {
    position: relative;
    width: 15%;
}

#form-product-create .table-label th p {
    position: absolute;
    top: 40%;
    left: 20%;
    // font-size: 20px;
}

#modal-product-detail .modal-footer-insert, 
#modal-product-detail .modal-footer-export {
    display: flex;
    justify-content: space-around;
}

#modal-product-detail .label-box {
    width: 60%;
    height: 350px;
    border: 1px solid #000000;
    margin: auto;
}

#table-products-div #table-products th, #table-products-div #table-products td {
    vertical-align: middle;
}

#table-products-div #table-products a {
    text-decoration: underline;
}


